<template>
  <div
    class="modal fade"
    id="donateModal"
    tabindex="-1"
    aria-labelledby="donateModalLabel"
    aria-hidden="true"
  >
    <div class="modal-dialog modal-dialog-centered">
      <div class="modal-content">
        <div class="modal-body">
          <div class="py-2 text-center">
            <i class="bi bi-cup-hot-fill fs-2 text-primary"></i>
            <h3>{{ $t("donation_modal.do_you_like_the_project") }}</h3>
            <h6 class="text-muted">
              {{ $t("donation_modal.buy_us_a_coffee") }}
            </h6>
          </div>

          <div class="d-grid gap-2 col-6 mx-auto">
            <a
              class="btn btn-primary"
              href="https://stellarchain.io/address/GAI3GJ2Q3B35AOZJ36C4ANE3HSS4NK7WI6DNO4ZSHRAX6NG7BMX6VJER"
              role="button"
              >{{ $t("donation_modal.donate_stellar") }}</a
            >
            <a
              class="btn btn-primary"
              href="https://commerce.coinbase.com/checkout/d21e084f-f942-4b2f-866c-177d11a38b7a"
              role="button"
              >{{ $t("donation_modal.donate_btc") }}
            </a>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { defineComponent } from "vue";
export default defineComponent({
  name: "DonationModal",
  setup() {
    return {};
  },
});
</script>
