<template>
  <div
    id="networkModal"
    class="modal fade"
    tabindex="-1"
    aria-labelledby="networkModalLabel"
    aria-hidden="true"
  >
    <div class="modal-dialog modal-dialog-scrollable modal-lg">
      <div class="modal-content">
        <div class="modal-header">
          <h5>{{ $t("navbar.network_address") }}</h5>
          <button
            type="button"
            class="btn-close"
            data-bs-dismiss="modal"
            aria-label="Close"
          ></button>
        </div>
        <div class="modal-body">
          <div class="mb-3">
            <label for="addressInput" class="form-label">
              {{ $t("navbar.enter_custom_address") }}</label
            >
            <input
              id="addressInput"
              v-model="address"
              type="url"
              class="form-control"
              placeholder="https://horizon.stellar.org"
            />
          </div>
        </div>
        <div class="modal-footer">
          <button
            type="button"
            class="btn btn-primary"
            :disabled="!address"
            data-bs-dismiss="modal"
            @click="changeNetworkType(NetworksEnum.customNet)"
          >
            {{ $t("navbar.save_changes") }}
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { defineComponent, ref } from "vue";
import { NetworksEnum } from "@/store/modules/settings/state";
import { useRoute } from "vue-router";

export default defineComponent({
  name: "NetworkModal",
  setup() {

    const route = useRoute();
    const address = ref(null);

    const changeNetworkType = (networkType) => {
      switch (networkType) {
        case NetworksEnum.liveNet:
          localStorage.removeItem("custom_net");
          window.location.href = process.env.VUE_APP_LIVENET_URL + route.path;
          break;
        case NetworksEnum.testNet:
          localStorage.removeItem("custom_net");
          window.location.href = process.env.VUE_APP_TESTNET_URL + route.path;
          break;
        case NetworksEnum.futureNet:
          localStorage.removeItem("custom_net");
          window.location.href = process.env.VUE_APP_FUTURENET_URL + route.path;
          break;
        case NetworksEnum.customNet:
          localStorage.setItem("custom_net", address.value);
          window.location.href = window.location.origin + route.path;
          break;
      }
    };

    return {changeNetworkType, address, NetworksEnum};
  },
});
</script>
