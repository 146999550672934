<template>
  <nav
    class="navbar navbar-expand-lg border-bottom "
    aria-label="navbar"
  >
    <div class="container">
      <router-link to="/" class="navbar-brand">
        <img
          :src="require(darkTheme ? '@/assets/images/logo-white.png' : '@/assets/images/logo.png')"
          style="height: 20px"
          :alt="$t('navbar.logo')"
      /></router-link>
      <button
        class="navbar-toggler"
        type="button"
        data-bs-toggle="collapse"
        data-bs-target="#nav"
        aria-controls="nav"
        aria-expanded="false"
        :aria-label="$t('navbar.toggle_navigation')"
      >
        <span class="navbar-toggler-icon"></span>
      </button>
      <div id="nav" class="navbar-collapse collapse">
        <ul class="navbar-nav justify-content-around  d-flex w-100">
          <li
            class="nav-item dropdown me-3"
            @mouseover="showDropdown"
            @mouseleave="hideDropdown"
          >
            <router-link
              class="nav-link"
              :class="{
                active: [
                  'LedgersRoute',
                  'TransactionsRoute',
                  'OperationsRoute',
                ].includes($route.name),
              }"
              data-bs-toggle="dropdown"
              aria-expanded="false"
              to="#"
            >
              <i class="bi bi-bar-chart-line text-primary small"></i>
              {{ $t("navbar.blockchain") }}
              <i class="bi bi-chevron-down small"></i>
            </router-link>
            <div
              class="dropdown-menu dropdown-menu-center shadow-sm border p-1"
            >
              <div class="list-group list-group-flush">
                <router-link
                  class="
                    list-group-item list-group-item-action
                    border-0
                    rounded
                  "
                  :class="{ active: $route.name === 'LedgersRoute' }"
                  style="min-width: 15rem"
                  :to="{
                    name: 'LedgersRoute',
                  }"
                >
                  <div class="d-flex justify-content-between align-items-center"
                    data-bs-toggle="collapse" 
                    data-bs-target=".navbar-collapse.show"
                  >
                    <div class="flex-shrink-0">
                      <i
                        class="bi bi-card-list fs-4"
                        :class="{
                          'text-primary': $route.name !== 'LedgersRoute',
                        }"
                      ></i>
                    </div>
                    <div class="flex-grow-1 ms-3">
                      <span class="d-block fw-bold mb-0 small">{{
                        $t("navbar.ledgers")
                      }}</span>
                      <span class="d-block small">{{
                        $t("navbar.data_structures")
                      }}</span>
                    </div>
                  </div>
                </router-link>
                <router-link
                  class="
                    list-group-item list-group-item-action
                    border-0
                    rounded
                  "
                  :class="{ active: $route.name === 'TransactionsRoute' }"
                  style="min-width: 15rem"
                  :to="{
                    name: 'TransactionsRoute',
                  }"
                >
                  <div class="d-flex align-items-center"
                    data-bs-toggle="collapse" 
                    data-bs-target=".navbar-collapse.show"
                  >
                    <div class="flex-shrink-0">
                      <i
                        class="bi bi-list-ul fs-4"
                        :class="{
                          'text-primary': $route.name !== 'TransactionsRoute',
                        }"
                      ></i>
                    </div>
                    <div class="flex-grow-1 ms-3">
                      <span class="d-block fw-bold mb-0 small">{{
                        $t("navbar.transactions")
                      }}</span>
                      <span class="d-block small">{{
                        $t("navbar.modifies_ledger_state")
                      }}</span>
                    </div>
                  </div>
                </router-link>
                <router-link
                  class="
                    list-group-item list-group-item-action
                    border-0
                    rounded
                  "
                  :class="{ active: $route.name === 'OperationsRoute' }"
                  style="min-width: 15rem"
                  :to="{
                    name: 'OperationsRoute',
                  }"
                >
                  <div class="d-flex align-items-center"
                    data-bs-toggle="collapse" 
                    data-bs-target=".navbar-collapse.show"
                    >
                    <div class="flex-shrink-0">
                      <i
                        class="bi bi-view-list fs-4"
                        :class="{
                          'text-primary': $route.name !== 'OperationsRoute',
                        }"
                      ></i>
                    </div>
                    <div class="flex-grow-1 ms-3">
                      <span class="d-block fw-bold mb-0 small">
                        {{ $t("navbar.operations") }}</span
                      >
                      <span class="d-block small">
                        {{ $t("navbar.mutates_the_ledger") }}</span
                      >
                    </div>
                  </div>
                </router-link>
                <router-link
                  class="
                    list-group-item list-group-item-action
                    border-0
                    rounded
                  "
                  :class="{ active: $route.name === 'ContractsRoute' }"
                  style="min-width: 15rem"
                  :to="{
                    name: 'ContractsRoute',
                  }"
                >
                  <div class="d-flex align-items-center"
                    data-bs-toggle="collapse" 
                    data-bs-target=".navbar-collapse.show"
                    >
                    <div class="flex-shrink-0">
                      <i
                        class="bi bi-journal fs-4"
                        :class="{
                          'text-primary': $route.name !== 'ContractsRoute',
                        }"
                      ></i>
                    </div>
                    <div class="d-flex flex-grow-1 ms-3">
                        <span>
                      <span class="d-block fw-bold mb-0 small">
                        {{ $t("navbar.contracts") }}
                      </span>
                      <span class="d-block small">
                        {{ $t("navbar.contracts_desc_navbar") }}</span
                      >
                        </span>
                      <span class="align-self-center badge bg-success">New</span> 
                    </div>
                  </div>
                </router-link>
                <router-link
                  class="
                    list-group-item list-group-item-action
                    border-0
                    rounded
                  "
                  :class="{ active: $route.name === 'LiquidityPoolsRoute' }"
                  style="min-width: 15rem"
                  :to="{
                    name: 'LiquidityPoolsRoute',
                  }"
                >
                  <div class="d-flex align-items-center"
                    data-bs-toggle="collapse" 
                    data-bs-target=".navbar-collapse.show"
                >
                    <div class="flex-shrink-0">
                      <i
                        class="bi bi-diagram-2 fs-4"
                        :class="{
                          'text-primary': $route.name !== 'LiquidityPoolsRoute',
                        }"
                      ></i>
                    </div>
                    <div class="flex-grow-1 ms-3">
                      <span class="d-block fw-bold mb-0 small">
                        {{ $t("navbar.liquidity_pools") }}</span
                      >
                      <span class="d-block small">
                        {{ $t("navbar.liquidity_pools_desc") }}</span
                      >
                    </div>
                  </div>
                </router-link>
              </div>
            </div>
          </li>
          <li
            class="nav-item dropdown me-3"
            @mouseover="showDropdown"
            @mouseleave="hideDropdown"
          >
            <router-link
              class="nav-link"
              data-bs-toggle="dropdown"
              aria-expanded="false"
              to="#"
              :class="{
                active: [
                  'TopAccountsRoute',
                  'DirectoryRoute',
                  'DirectoryUpdateRoute',
                ].includes($route.name),
              }"
            >
            <i class="bi bi-person-check text-primary small"></i>
              {{ $t("navbar.accounts") }}
              <i class="bi bi-chevron-down small"></i> 
            </router-link>
            <div
              class="dropdown-menu dropdown-menu-center shadow-sm border p-1"
            >
              <div class="list-group list-group-flush">
                <router-link
                  :to="{ name: 'TopAccountsRoute' }"
                  class="
                    list-group-item list-group-item-action
                    border-0
                    rounded
                  "
                  :class="{ active: $route.name === 'TopAccountsRoute' }"
                  style="min-width: 15rem"
                >
                  <div class="d-flex align-items-center"
                    data-bs-toggle="collapse" 
                    data-bs-target=".navbar-collapse.show"
                    >
                    <div class="flex-shrink-0">
                      <i
                        class="bi bi-star-fill fs-4"
                        :class="{
                          'text-warning': $route.name !== 'TopAccountsRoute',
                        }"
                      ></i>
                    </div>
                    <div class="flex-grow-1 ms-3">
                      <span class="d-block fw-bold mb-0 small">{{
                        $t("navbar.top_accounts")
                      }}</span>
                      <span class="d-block small">{{
                        $t("navbar.stellar_rich_list")
                      }}</span>
                    </div>
                  </div>
                </router-link>
                <router-link
                  :to="{ name: 'DirectoryRoute' }"
                  class="
                    list-group-item list-group-item-action
                    border-0
                    rounded
                  "
                  :class="{ active: $route.name === 'DirectoryRoute' }"
                  style="min-width: 15rem"
                >
                  <div class="d-flex align-items-center"
                    data-bs-toggle="collapse" 
                    data-bs-target=".navbar-collapse.show"
                  >
                    <div class="flex-shrink-0">
                      <i
                        class="bi bi-tag-fill fs-4"
                        :class="{
                          'text-primary': $route.name !== 'DirectoryRoute',
                        }"
                      ></i>
                    </div>
                    <div class="flex-grow-1 ms-3">
                      <span class="d-block fw-bold mb-0 small"
                        >{{ $t("navbar.directory") }}
                      </span>
                      <span class="d-block small">{{
                        $t("navbar.well_known_accounts")
                      }}</span>
                    </div>
                  </div>
                </router-link>
                <router-link
                  :to="{ name: 'DirectoryUpdateRoute' }"
                  class="
                    list-group-item list-group-item-action
                    border-0
                    rounded
                  "
                  :class="{ active: $route.name === 'DirectoryUpdateRoute' }"
                  style="min-width: 15rem"
                >
                  <div class="d-flex align-items-center"
                    data-bs-toggle="collapse" 
                    data-bs-target=".navbar-collapse.show"
                    >
                    <div class="flex-shrink-0">
                      <i
                        class="bi bi-plus-circle fs-4"
                        :class="{
                          'text-success':
                            $route.name !== 'DirectoryUpdateRoute',
                        }"
                      ></i>
                    </div>
                    <div class="flex-grow-1 ms-3">
                      <span class="d-block fw-bold mb-0 small">{{
                        $t("navbar.add_label")
                      }}</span>
                      <span class="d-block small">{{
                        $t("navbar.name_your_address")
                      }}</span>
                    </div>
                  </div>
                </router-link>
              </div>
            </div>
          </li>
          <li class="nav-item" style="display:block">
            <router-link
              class="nav-link"
              :class="{ active: $route.name === 'MarketsRoute' }"
              :to="{
                name: 'MarketsRoute',
              }"
            >
            <span
                data-bs-toggle="collapse" 
                data-bs-target=".navbar-collapse.show"
            >
            <i class="bi bi-bank text-primary small"></i>
                {{ $t("navbar.markets") }}</span>
            </router-link>
          </li>
          <li class="nav-item">
            <router-link
              class="nav-link"
              :class="{ active: $route.name === 'AssetsRoute' }"
              :to="{
                name: 'AssetsRoute',
              }"
            >
            <span
                data-bs-toggle="collapse" 
                data-bs-target=".navbar-collapse.show"
            >
                <i class="bi bi-wallet text-primary small"></i>
                {{ $t("navbar.assets") }}</span>
            </router-link>
          </li>
          <li
            class="nav-item dropdown me-3"
            @mouseover="showDropdown"
            @mouseleave="hideDropdown"
          >
            <router-link
              to="#"
              class="nav-link"
              :class="{
                active: [
                  'PriceRoute',
                  'LedgerDetailsChartsRoute',
                  'AnalyticsRoute',
                ].includes($route.name),
              }"
              data-bs-toggle="dropdown"
              aria-expanded="false"
            ><i class="bi bi-graph-up-arrow text-primary small"></i>
              {{ $t("navbar.charts") }}
              <i class="bi bi-chevron-down small"></i>
            </router-link>
            <div
              class="dropdown-menu dropdown-menu-center shadow-sm border p-1"
            >
              <div class="list-group list-group-flush">
                <router-link
                  :to="{ name: 'PriceRoute' }"
                  class="
                    list-group-item list-group-item-action
                    border-0
                    rounded
                  "
                  :class="{ active: $route.name === 'PriceRoute' }"
                  style="min-width: 15rem"
                >
                  <div class="d-flex align-items-center"
                    data-bs-toggle="collapse" 
                    data-bs-target=".navbar-collapse.show"
                  >
                    <div class="flex-shrink-0">
                      <i
                        class="bi bi-coin fs-4"
                        :class="{
                          'text-primary': $route.name !== 'PriceRoute',
                        }"
                      ></i>
                    </div>
                    <div class="flex-grow-1 ms-3">
                      <span class="d-block fw-bold mb-0 small"
                        >{{ $t("navbar.price") }}
                      </span>
                      <span class="d-block small">
                        {{ $t("navbar.currency_statistics") }}</span
                      >
                    </div>
                  </div>
                </router-link>
                <router-link
                  :to="{ name: 'LedgerDetailsChartsRoute' }"
                  class="
                    list-group-item list-group-item-action
                    border-0
                    rounded
                  "
                  :class="{
                    active: $route.name === 'LedgerDetailsChartsRoute',
                  }"
                  style="min-width: 15rem"
                >
                  <div class="d-flex align-items-center">
                    <div class="flex-shrink-0">
                      <i
                        class="bi bi-pie-chart-fill fs-4"
                        :class="{
                          'text-warning':
                            $route.name !== 'LedgerDetailsChartsRoute',
                        }"
                      ></i>
                    </div>
                    <div class="flex-grow-1 ms-3">
                      <span class="d-block fw-bold mb-0 small">{{
                        $t("navbar.ledger_details")
                      }}</span>
                      <span class="d-block small">{{
                        $t("navbar.historical_information")
                      }}</span>
                    </div>
                  </div>
                </router-link>
                <router-link
                  :to="{ name: 'AnalyticsRoute' }"
                  class="
                    list-group-item list-group-item-action
                    border-0
                    rounded
                  "
                  :class="{ active: $route.name === 'AnalyticsRoute' }"
                  style="min-width: 15rem"
                >
                  <div class="d-flex align-items-center">
                    <div class="flex-shrink-0">
                      <i
                        class="bi bi-globe fs-4"
                        :class="{
                          'text-info': $route.name !== 'AnalyticsRoute',
                        }"
                      ></i>
                    </div>
                    <div class="flex-grow-1 ms-3">
                      <span class="d-block fw-bold mb-0 small">
                        {{ $t("navbar.network_activity") }}</span
                      >
                      <span class="d-block small">{{
                        $t("navbar.performance_over_time")
                      }}</span>
                    </div>
                  </div>
                </router-link>
              </div>
            </div>
          </li>
        </ul>
        <ul class="navbar-nav">
          <li class="nav-item me-3">
            <a class="btn hover nav-link" href="#"
              ><i
                class="bi bi-heart-fill text-danger"
                data-bs-toggle="modal"
                data-bs-target="#donateModal"
              ></i
            ></a>
          </li>
          <li
            class="nav-item dropdown"
            @mouseover="showDropdown"
            @mouseleave="hideDropdown"
          >
            <router-link
              to="#"
              class="btn btn-outline-primary p-1 align-items-center d-flex gap-1 px-2"
              data-bs-toggle="dropdown"
              aria-expanded="false"
            >
              <span>{{ network() }} </span>
              <i class="bi bi-chevron-down small"></i>
            </router-link>
            <div
              class="dropdown-menu dropdown-menu-center shadow-sm border p-1"
            >
              <div class="list-group list-group-flush">
                <button
                  :class="`
                    list-group-item list-group-item-action
                    border-0
                    rounded 
                    ${networkType === NetworksEnum.liveNet ? 'active' : ''}`"
                  style="min-width: 15rem"
                  @click="changeNetworkType(NetworksEnum.liveNet)"
                >
                  <div class="d-flex align-items-center">
                    <div class="flex-shrink-0">
                      <i
                        :class="`bi bi-globe fs-4`"
                      ></i>
                    </div>
                    <div class="flex-grow-1 ms-3">
                      <span class="d-block fw-bold mb-0 small">{{
                        $t("navbar.live_network")
                      }}</span>
                      <span
                        :class="`d-block small ${
                          networkType === NetworksEnum.liveNet
                            ? 'text-white'
                            : 'text-muted'
                        }`"
                      >
                        {{ $t("navbar.main_production_network") }}</span
                      >
                    </div>
                  </div>
                </button>
                <button
                  :class="`
                    list-group-item list-group-item-action
                    border-0
                    rounded
                    ${networkType === NetworksEnum.testNet ? 'active' : ''}`"
                  style="min-width: 15rem"
                  @click="changeNetworkType(NetworksEnum.testNet)"
                >
                  <div class="d-flex align-items-center">
                    <div class="flex-shrink-0">
                      <i
                        :class="`bi bi-tools fs-4`"
                      ></i>
                    </div>
                    <div class="flex-grow-1 ms-3">
                      <span class="d-block fw-bold mb-0 small">
                        {{ $t("navbar.test_network") }}</span
                      >
                      <span
                        :class="`d-block text small ${
                          networkType === NetworksEnum.testNet
                            ? 'text-white'
                            : 'text-muted'
                        }`"
                      >
                        {{ $t("navbar.testing_environment") }}</span
                      >
                    </div>
                  </div>
                </button>
                <button
                  type="button"
                  class="
                    list-group-item list-group-item-action
                    border-0
                    rounded
                  "
                  style="min-width: 15rem"
                  data-bs-toggle="modal"
                  data-bs-target="#networkModal"
                >
                  <div class="d-flex align-items-center">
                    <div class="flex-shrink-0">
                      <i class="bi bi-code-square fs-4"></i>
                    </div>
                    <div class="flex-grow-1 ms-3">
                      <span class="d-block fw-bold mb-0 small">{{
                        $t("navbar.custom_network")
                      }}</span>
                      <span class="d-block text-muted small">{{
                        $t("navbar.bring_your_own")
                      }}</span>
                    </div>
                  </div>
                </button>
              </div>
            </div>
          </li>
          <li class="nav-item" @click="changeColorTheme">
            <div class="btn hover ms-3">
              <i v-if="!darkTheme" class="bi bi-moon-stars"></i>
              <i v-if="darkTheme" class="bi bi-brightness-high"></i>
            </div>
          </li>
        </ul>
      </div>
    </div>
  </nav>
</template>

<script>
import { defineComponent, computed, ref } from "vue";
import { useStore } from "vuex";
import { useI18n } from "vue-i18n";
import { useRoute } from "vue-router";
import { NetworksEnum } from "@/store/modules/settings/state";

export default defineComponent({
  name: "NavBar",
  setup() {
    const store = useStore();
    const route = useRoute();
    const { t } = useI18n();
    const show = ref(false);
    const address = ref(null);

    const networkType = computed(
      () => store.getters["settings/getNetworkType"]
    );

    const network = () => {
      switch (networkType.value) {
        case NetworksEnum.liveNet:
          return t("navbar.livenet");
        case NetworksEnum.testNet:
          return t("navbar.testnet");
        case NetworksEnum.futureNet:
          return t("navbar.futurenet");  
        case NetworksEnum.customNet:
          return t("navbar.customnet");
      }
    };
    const showDropdown = (e) => {
      if (
        window.innerWidth > 992 &&
        e.target.getAttribute("data-bs-toggle") === "dropdown"
      ) {
        const link = e.target;
        link.classList.add("show");
        link.nextElementSibling.classList.add("show");
      }
    };

    const darkTheme = computed(() => store.getters["settings/getThemeColor"]);
    const changeColorTheme = () =>  {
        const themes = {
            true: 'dark',
            false : 'light'
        };
        document.cookie = `darkMode=${!darkTheme.value}; expires=Fri, 31 Dec 9999 23:59:59 GMT; path=/`;
        document.documentElement.setAttribute('data-bs-theme', themes[!darkTheme.value]);
        store.dispatch('settings/changeColorTheme', !darkTheme.value);
    }

    const hideDropdown = (e) => {
      const link = e.target.firstChild;
      link.classList.remove("show");
      link.nextElementSibling.classList.remove("show");
    };

    const changeNetworkType = (networkType) => {
      switch (networkType) {
        case NetworksEnum.liveNet:
          localStorage.removeItem("custom_net");
          window.location.href = process.env.VUE_APP_LIVENET_URL + route.path;
          break;
        case NetworksEnum.testNet:
          localStorage.removeItem("custom_net");
          window.location.href = process.env.VUE_APP_TESTNET_URL + route.path;
          break;
        case NetworksEnum.futureNet:
          localStorage.removeItem("custom_net");
          window.location.href = process.env.VUE_APP_FUTURENET_URL + route.path;
          break;
        case NetworksEnum.customNet:
          localStorage.setItem("custom_net", address.value);
          window.location.href = window.location.origin + route.path;
          break;
      }
    };

    return {
      store,
      showDropdown,
      hideDropdown,
      show,
      NetworksEnum,
      networkType,
      network,
      address,
      changeNetworkType,
      darkTheme,
      changeColorTheme
    };
  },
});
</script>
<style>
@media (min-width: 768px) {
  .dropdown-menu-center {
    right: auto;
    left: 50%;
    -webkit-transform: translate(-50%, 0);
    -o-transform: translate(-50%, 0);
    transform: translate(-50%, 0);
  }
}
</style>

