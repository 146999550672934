<template>
  <div class="mt-auto">
    <footer class="footer bg-body border-top align-self-end">
      <div class="container py-3">
        <span class="text-muted small"
        >{{ $t("main_footer.credits") }} - {{(new Date().getFullYear())}}
          <img 
           :src="require(colorMode ? '@/assets/images/logo-white.png' : '@/assets/images/logo.png')"
           style="height: 14px"
           />
        </span>

        <div class="float-end">
          <ul class="list-inline m-0">
            <li class="list-inline-item small">
              <router-link
                to="#"
                class="text-muted text-decoration-none"
                data-bs-toggle="modal"
                data-bs-target="#donateModal"
                >{{ $t("main_footer.donate") }}</router-link
              >
            </li>
          </ul>
        </div>
      </div>
    </footer>
  </div>
</template>

<script>
import { computed, defineComponent } from "vue";
import { useStore } from 'vuex';

export default defineComponent({
  name: "MainFooter",
  setup() {
    const store = useStore();

    const sendEmail = () => {
      window.location.href = "mailto:support@stellarchain.io";
    };

    const colorMode = computed(() => store.getters["settings/getThemeColor"]);
    return { sendEmail, colorMode };
  },
});
</script>
