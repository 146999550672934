<template>
  <div class="d-flex flex-column min-vh-100 bg-body-tertiary">
    <div class="fixed-top bg-body">
        <nav-bar />
        <breadcrumbs />
    </div>
    <div class="container py-2" style="margin-top:95px">
      <router-view :key="$route.path" />
    </div>
    <main-footer />
    <network-modal />
    <donation-modal />
  </div>
</template>

<script>
import NavBar from "@/components/NavBar.vue";
import Breadcrumbs from "@/components/Breadcrumbs.vue";
import MainFooter from "@/components/MainFooter.vue";
import DonationModal from "@/components/modals/DonationModal.vue";
import NetworkModal from "@/components/modals/NetworkModal.vue";

export default {
  components: {
    NavBar,
    Breadcrumbs,
    MainFooter,
    DonationModal,
    NetworkModal
  },
  setup() {},
};
</script>



