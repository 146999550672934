<template>
  <div class="py-2 border-bottom small">
    <div class="container-lg d-flex justify-content-between">
      <nav aria-label="breadcrumb">
        <ol class="breadcrumb mb-0">
          <li
            v-for="(breadcrumb, idx) in breadcrumbs"
            :key="idx"
            :class="`breadcrumb-item ${
              idx === breadcrumbs.length - 1 ? 'active' : ''
            }`"
            aria-current="page"
          >
            <router-link
              v-if="idx !== breadcrumbs.length - 1"
              class="text-decoration-none"
              :to="breadcrumb.to"
            >
              {{ breadcrumb.text }}</router-link
            >
            <template v-else>
              {{ breadcrumb.text }}
            </template>
          </li>
        </ol>
      </nav>
      <span class="text-muted">XLM Price: ${{parseFloat(latestPrice.value).toLocaleString()}}</span>
    </div>
  </div>
</template>

<script>
import { defineComponent, computed } from "vue";
import { useRoute } from "vue-router";
import { useStore } from "vuex";
import { formatBreadcrumbsDate } from "@/utils/date.js";

export default defineComponent({
  name: "Breadcrumbs",
  setup() {
    const route = useRoute();
    const store = useStore();

    const now = computed(() => Date.now());
    const isSlateEmpty = computed(
      () => store.getters["accounts/getIsSlateEmpty"]
    );

    store.dispatch("assets/getApiLatestXlmPrice");
    const latestPrice = computed(() => store.getters["assets/getLatestXlmPrice"])

    const breadcrumbs = computed(() => {
      if (route.path === "/")
        return [{ to: "/", text: route.matched[0].meta.breadcrumb }];
      let pathArray = route.path.split("/");
      pathArray.shift();
      if (pathArray.includes("order")) {
        return [{ to: "", text: "Order " + pathArray[pathArray.length - 1] }];
      }
      let breadcrumbs = pathArray.reduce((breadcrumbArray, path, idx) => {
        breadcrumbArray.push({
          path: path,
          to: breadcrumbArray[idx - 1]
            ? "/" + breadcrumbArray[idx - 1].path + "/" + path
            : "/" + path,
          text: route.matched[idx].meta.breadcrumb || path,
        });
        return breadcrumbArray;
      }, []);
      return breadcrumbs;
    });

    return { breadcrumbs, formatBreadcrumbsDate, now, latestPrice};
  },
});
</script>
